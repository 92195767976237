const config = {
  Auth: {
    userPoolId: 'eu-central-1_FpBM5cK4T',
    userPoolWebClientId: '3v97el7p8pbrjh8hj7nmokfjcq',
    region: 'eu-central-1',
    mandatorySignIn: true,
    ssoServiceProvider: 'MakerStreetGoogleWorkplacesSso',
    oauth: {
      domain: 'makerstreet-prod.auth.eu-central-1.amazoncognito.com',
      redirectSignIn: 'https://ms-insights.nl',
      redirectSignOut: 'https://ms-insights.nl',
      scope: ['phone', 'email', 'openid', 'profile'],
      responseType: 'code',
    },
  },
  API: {
    aws_appsync_graphqlEndpoint: 'https://5hducbycwvek3kbnwuagz77fwe.appsync-api.eu-central-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-central-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
}

export default config
